import React from "react"
import { Link } from "gatsby"

const VoyageCard = ({ title, excerpt, date, to }) => {
    return (
        <div className="px-2 my-5 lg:w-1/4 md:w-1/3 sm:w-1/2 md:mx-0 mx-5 w-full md:mx-5 bg-gray-300 rounded-lg">
            <Link className='hover:no-underline text-bodyGray hover:text-black' to={to}>
                <div className="flex rounded-lg h-full p-8 flex-col">
                    <div className="flex items-center mb-3">
                        <h2 className="text-gray-900 text-2xl font-semibold title-font font-medium">
                            {title}
                        </h2>
                    </div>
                    <div className="flex-grow">
                        <p className="leading-relaxed text-base">{excerpt}</p>
                        <br />
                        <p>{date}</p>
                        <Link
                            to={to}
                            className="mt-3 text-indigo-600 inline-flex items-center"
                        >
                            Read More
                            <svg
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                className="w-4 h-4 ml-2"
                                viewBox="0 0 24 24"
                            >
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default VoyageCard
