import React from "react"
import {graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import VoyageCard from "../components/VoyageCard"

const Voyage = ({ data, location }) => {
    // const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
        <Layout location={location}>
            <h1 className="text-center uppercase mb-5 md:text-6xl text-5xl font-logoFont">
                The Voyage
            </h1>
            <SEO title="Voyage" />
            <div className="w-full flex justify-center">
                <div
                    style={{ maxWidth: "80%" }}
                    className="flex justify-center items-center flex-wrap"
                >
                    {posts.map(({ node }) => {
                        const title = node.frontmatter.title || node.fields.slug
                        const date = node.frontmatter.date
                        const slug = node.fields.slug
                        const excerpt =
                            node.frontmatter.description || node.excerpt
                        return (
                            <VoyageCard
                                date={date}
                                to={`/voyage${slug}`}
                                title={title}
                                excerpt={excerpt}
                                key={slug}
                            />
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}
export default Voyage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMdx(
            filter: { frontmatter: { postType: { eq: "voyage" } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`
